import React, { useState, useEffect, useRef } from "react"
import { isBrowser, removeHost } from "../../utils/functions"
import OrderStepIndyficator from "../../components/orderStepIndyficator/OrderStepIndyficator"
import countryList from "../../utils/country-list"
import Spin from "../spin/Spin"
import { useForm } from "react-hook-form"
import { useMutation } from "@apollo/client"
import CheckoutProducts from "./products/Products"
import CheckoutCoupons from "./coupons/Coupons"
import Shipping from "../shipping/Shipping"
import CHECKOUT_MUTATION from "../../mutations/checkout"
import { isEmpty } from "lodash"
import "./style.scss"
import { Helmet } from "react-helmet"
import { navigate } from "gatsby"
import PaymentsMethods from "./products/paymentsMethods/PaymentsMethods"

const Checkout = ({
  orderPath,
  checkout,
  authData,
  cart,
  shippingMethods,
  shippingMethodsMessage,
  shippingMethodsChange,
  shippingID,
  paymentsMethods,
  paymentID,
  handleChangePayment,
  loadingShipping,
  tempShippingID,
  setOrderData,
  payUData,
  toogleWithPickup,
  withPickup,
  payment: isPay,
  lang
}) => {
  const {
    errorRequired,
    inpostButton,
    inpostBoxPlaceholder,
    orderHeading,
    paymentHeading,
    order,
    payment,
    errorPhone,
    errorEmail,
    // orderData,
    inpostNotSelected,
    thankyouPage,
  } = checkout

  // const { cart } = useContext(AppContext);
  const {
    buttonText,
    couponLabel,
    newsletterConsentLabel,
    paymentLabel,
    productLabel,
    regulationsConsentLabel,
    shipingLabel,
    totalLabel,
    totalVatText,
    priceLabel,
  } = order

  const {
    cityLabel,
    commentLabel,
    vatLabel,
    companyLabel,
    countryLabel,
    differentShippingLabel,
    emailLabel,
    streetLabel,
    nameLabel,
    nipLabel,
    phoneLabel,
    surnameLabel,
    zipCodeLabel,
  } = payment

  // console.log([paymentsMethods, withPickup])

  const [errorMessage, setErrorMessage] = useState("")

  const [vat, setVat] = useState(false)
  const [shipping, toogleShipping] = useState(false)

  const [sending, setSending] = useState(false)
  // const [update, setUpdate] = useState(false)

  const [inpostID, setInpostID] = useState("")
  const [inpostError, setInpostError] = useState(false)
  const [inpostValue, setInpostValue] = useState("")

  const [nip, setNip] = useState("")

  // const [tmpShippingID, setTmpShippingID] = useState('');
  const payuPlaceOrder = useRef(null)
  const payuTokenType = useRef(null)
  const payuValue = useRef(null)
  const payuMaskedCard = useRef(null)
  const payuType = useRef(null)

  const [checkoutFields, setCheckoutFields] = useState({
    billing: {
      address1: "",
      city: "",
      company: "",
      country: lang,
      email: "",
      firstName: "",
      lastName: "",
      phone: "",
      postcode: "",
      // nip: "",
    },
    shipping: {
      address1: "",
      city: "",
      company: "",
      country: lang,
      email: "",
      phone: "",
      postcode: "",
      firstName: "",
      lastName: "",
    },
    createAccount: false,
    customerNote: "",
    paymentMethod: "",
    shipToDifferentAddress: false,
    shippingMethod: "",
    // account: {
    //   password: "",
    //   username: ''
    // },
    metaData: [],
  })


  const [customer, setCustomer] = useState(
    !isEmpty(authData) ? authData.customer : {}
  )

  useEffect(() => {
    if (!isEmpty(authData)) {
      setCustomer(authData.customer)
      if (!isEmpty(authData.customer)) {
        if (!isEmpty(authData.customer.metaData)) {
          setNip(authData.customer.metaData[0].value)
        }
      }
    }

    if (isBrowser()) {
      window.easyPackAsyncInit = function () {
        window.easyPack.init({
          defaultLocale: "pl",
          mapType: "osm",
          searchType: "osm",
          points: {
            types: ["parcel_locker_only"],
          },
          map: {
            useGeolocation: true,
            initialTypes: ["parcel_locker_only"],
          },
        })
      }
    }
  }, [authData])

  const openModal = () => {
    window.easyPack.modalMap(
      function (point, modal) {
        modal.closeModal()

        setInpostValue(
          point.name + ", " + point.address.line1 + ", " + point.address.line2
        )
        setInpostID(point.name)
      },
      { width: 500, height: 600 }
    )
  }

  const inpostHendleClick = e => {
    openModal()
  }

  const handleChangeShipping = (e, delivery) => {
    shippingMethodsChange(e)
    if (delivery == "true") {
      toogleWithPickup(true)
    } else {
      toogleWithPickup(false)
    }
    // delivery
    // setTmpShippingID(e.target.value)
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      billingAddress1: !isEmpty(customer)
        ? customer.billing.address1
          ? customer.billing.address1
          : ""
        : "",
      billingCity: !isEmpty(customer)
        ? customer.billing.city
          ? customer.billing.city
          : ""
        : "",
      billingCompany: !isEmpty(customer)
        ? customer.billing.company
          ? customer.billing.company
          : ""
        : "",
      billingCountry: !isEmpty(customer)
        ? customer.billing.country
          ? customer.billing.country
          : lang
        : lang,
      billingEmail: !isEmpty(customer)
        ? customer.billing.email
          ? customer.billing.email
          : ""
        : "",
      billingFirstName: !isEmpty(customer)
        ? customer.billing.firstName
          ? customer.billing.firstName
          : ""
        : "",
      billingLastName: !isEmpty(customer)
        ? customer.billing.lastName
          ? customer.billing.lastName
          : ""
        : "",
      billingPhone: !isEmpty(customer)
        ? customer.billing.phone
          ? customer.billing.phone
          : ""
        : "",
      billingPostcode: !isEmpty(customer)
        ? customer.billing.postcode
          ? customer.billing.postcode
          : ""
        : "",
      billingNip: !isEmpty(customer) ? nip : "",
      //
      shippingaddress1: !isEmpty(customer)
        ? customer.shipping.address1
          ? customer.shipping.address1
          : ""
        : "",
      shippingCity: !isEmpty(customer)
        ? customer.shipping.city
          ? customer.shipping.city
          : ""
        : "",
      shippingPostcode: !isEmpty(customer)
        ? customer.shipping.postcode
          ? customer.shipping.postcode
          : ""
        : "",
      shippingCountry: !isEmpty(customer)
        ? customer.shipping.country
          ? customer.shipping.country
          : lang
        : lang,
      shippingEmail: !isEmpty(customer)
        ? customer.shipping.email
          ? customer.shipping.email
          : ""
        : "",
      shippingPhone: !isEmpty(customer)
        ? customer.shipping.phone
          ? customer.shipping.phone
          : ""
        : "",
      shippingFirstName: !isEmpty(customer)
        ? customer.shipping.firstName
          ? customer.shipping.firstName
          : ""
        : "",
      shippingLastName: !isEmpty(customer)
        ? customer.shipping.lastName
          ? customer.shipping.lastName
          : ""
        : "",
    },
  })

  const billingCountry = register("billingCountry", {
    required: true,
  })

  const shippingCountry = register("shippingCountry", {
    required: true,
  })

  const onSubmit = (values, e) => {
    if (sending) return
    setInpostError(false)
    setErrorMessage("")
    let errorInpost = false
    if (shippingMethodsMessage !== "virtual") {
      shippingMethods.forEach(element => {
        if (element.id_shipping === tempShippingID && element.integration) {
          if (isEmpty(inpostID)) {
            setErrorMessage(inpostNotSelected)
            setInpostError(true)
            errorInpost = true
            return
          }
        }
      })
    }

    if (errorInpost) {
      return
    }

    if (cart?.subscription) {
      if (
        payuTokenType.current.value &&
        payuValue.current.value &&
        payuMaskedCard.current.value &&
        payuType.current.value
      ) {
        const data = {
          tokenType: payuTokenType.current.value,
          value: payuValue.current.value,
          maskedCard: payuMaskedCard.current.value,
          type: payuType.current.value,
        }
        wrapperSetCheckoutFields(values, data)
        setSending(true)
        checkoutAction()
      } else {
        payuPlaceOrder.current.click()
      }
    } else {
      wrapperSetCheckoutFields(values)
      setSending(true)
      checkoutAction()
    }
  }

  const wrapperSetCheckoutFields = (values, data = {}) => {
    // console.log(tempShippingID);
    // console.log(shippingID);
    // flexible_shipping_single:2

    let isPaid = false
    if (isPay) {
      isPaid = true
    }

    if (withPickup) {
      isPaid = true
    }

    setCheckoutFields({
      billing: {
        address1: values.billingAddress1,
        city: values.billingCity,
        company: values.billingCompany,
        country: values.billingCountry,
        email: values.billingEmail,
        firstName: values.billingFirstName,
        lastName: values.billingLastName,
        phone: values.billingPhone,
        postcode: values.billingPostcode,
        // nip: "",
      },
      shipping: {
        address1: shipping ? values.shippingaddress1 : "",
        postcode: shipping ? values.shippingPostCode : "",
        city: shipping ? values.shippingCity : "",
        country: shipping ? values.shippingCountry : values.billingCountry,
        email: shipping ? values.shippingEmail : "",
        phone: shipping ? values.shippingPhone : "",
        firstName: shipping ? values.shippingFirstName : "",
        lastName: shipping ? values.shippingLastName : "",
      },
      isPaid: isPaid,
      customerNote: values.comment,
      paymentMethod: paymentID,
      shipToDifferentAddress: shipping ? true : false,
      shippingMethod: tempShippingID ? tempShippingID : shippingID,

      // account: {
      //   password: "",
      //   username: ''
      // },
      metaData: [
        {
          key: "_billing_nip",
          value: values.billingNip ? values.billingNip : "",
        },
        {
          key: "_billing_faktura",
          value: vat ? "1" : "0",
        },
        {
          key: "_terms",
          value: "" + values.regulations,
        },
        {
          key: "gr_checkout_checkbox",
          value: values.newsletter ? "1" : "0",
        },
        {
          key: "_gr_checkout_checkbox",
          value: values.newsletter ? "1" : "0",
        },
        {
          key: "_paczkomat_id",
          value: inpostID ? inpostID : "",
        },
        {
          key: "paczkomat_id",
          value: inpostID ? inpostID : "",
        },
        {
          key: "payu_subscription",
          value: cart.subscription ? "1" : "0",
        },
        {
          key: "payu_token_type",
          value: data.tokenType ? data.tokenType : "",
        },
        {
          key: "payu_value",
          value: data.value ? data.value : "",
        },
        {
          key: "payu_masked_card",
          value: data.maskedCard ? data.maskedCard : "",
        },
        {
          key: "payu_type",
          value: data.type ? data.type : "",
        },
      ],
    })
  }

  const [checkoutAction] = useMutation(CHECKOUT_MUTATION, {
    variables: {
      input: checkoutFields,
    },
    onCompleted: data => {
      // console.warn( 'completed CHECKOUT_MUTATION' );
      // refetch();
      const tmp = {
        checkoutData: {
          ...data,
        },
        // shipping: '',
        // paymanet: '',
        withPickup: withPickup,
        isPay: isPay,
        cartData: {
          ...cart,
        },
      }
      setOrderData(tmp)
      localStorage.setItem("woo-order", JSON.stringify(tmp))
      setSending(false)
      if (isBrowser()) {
        if (tmp.checkoutData.checkout.redirect) {
          navigate(tmp.checkoutData.checkout.redirect)
        } else {
          navigate(removeHost(thankyouPage.url))
        }
      }
    },
    onError: error => {
      if (error) {
        setSending(false)
        console.log(error)
        // setErrorMessage(error.graphQLErrors[0].message)

        // setRequestError(error.graphQLErrors[0].message);
      }
    },
  })

  return (
    <>
      {cart?.subscription && !isEmpty(payUData) ? (
        <Helmet>
          <script
            src={`${payUData.subscriptionData.widget_url}`}
            pay-button="#payu_place_order"
            merchant-pos-id={`${payUData.subscriptionData.merchant_pos_id}`}
            shop-name={`${payUData.subscriptionData.shop_name}`}
            total-amount={`${payUData.subscriptionData.total_amount}`}
            currency-code={`${payUData.subscriptionData.currency_code}`}
            customer-language={`${payUData.subscriptionData.customer_language}`}
            store-card={`${payUData.subscriptionData.store_card}`}
            recurring-payment={`${payUData.subscriptionData.recurring_payment}`}
            customer-email={`${payUData.subscriptionData.customer_email}`}
            sig={`${payUData.subscriptionData.sig}`}
            success-callback="payu_subscription_callback"
          ></script>
          <script>
            {`
              function payu_subscription_callback (rensponse){
                document.querySelector('#payu_token_type').value = rensponse.tokenType;
                document.querySelector('#payu_value').value = rensponse.value;
                document.querySelector('#payu_masked_card').value = rensponse.maskedCard;
                document.querySelector('#payu_type').value = rensponse.type;
                document.querySelector('#payButton').click();
              }
            `}
          </script>
        </Helmet>
      ) : (
        ""
      )}

      <div className="checkout">
        <OrderStepIndyficator
          orderPath={orderPath.stepIndyficator}
          activeStep={2}
        />
        <section className="container">
          <form className="checkout__wrapper" onSubmit={handleSubmit(onSubmit)}>
            <div className="checkout__column checkout__column--data">
              <h2 className="checkout__heading heading--big heading--dash">
                {paymentHeading}
              </h2>
              <div className="form__row">
                <div className="form__column">
                  <div className="form__input">
                    <label className="form__label" htmlFor="billingFirstName">
                      {nameLabel}
                      <span className="form__label__required">*</span>
                    </label>
                    <input
                      id="billingFirstName"
                      type="text"
                      {...register("billingFirstName", {
                        required: true,
                      })}
                    />
                    <span className="form__error">
                      {errors.billingFirstName && errorRequired}
                    </span>
                  </div>
                </div>

                <div className="form__column">
                  <div className="form__input">
                    <label className="form__label" htmlFor="billingLastName">
                      {surnameLabel}
                      <span className="form__label__required">*</span>
                    </label>
                    <input
                      id="billingLastName"
                      type="text"
                      {...register("billingLastName", {
                        required: true,
                      })}
                    />
                    <span className="form__error">
                      {errors.billingLastName && errorRequired}
                    </span>
                  </div>
                </div>
              </div>

              <div className="form__row">
                <div className="form__checkbox">
                  <label htmlFor="toogleVat">
                    <input
                      id="toogleVat"
                      type="checkbox"
                      {...register("toogleVat")}
                      onChange={() => {
                        setVat(!vat)
                      }}
                    />
                    <span
                      className="form__checkbox__info form__checkbox--normal"
                      dangerouslySetInnerHTML={{ __html: vatLabel }}
                    />
                  </label>
                </div>
              </div>

              <div className={`form__row ${vat ? "" : "form__row--hidden"}`}>
                <div className="form__column">
                  <div className="form__input">
                    <label className="form__label" htmlFor="billingCompany">
                      {companyLabel}
                      <span className="form__label__required">*</span>
                    </label>
                    <input
                      id="billingCompany"
                      type="text"
                      {...register("billingCompany", {
                        require: vat,
                      })}
                    />
                    <span className="form__error">
                      {errors.billingCompany && errorRequired}
                    </span>
                  </div>
                </div>

                <div className="form__column">
                  <div className="form__input">
                    <label className="form__label" htmlFor="billingNip">
                      {nipLabel}
                      <span className="form__label__required">*</span>
                    </label>
                    <input
                      id="billingNip"
                      type="text"
                      {...register("billingNip", {
                        require: vat,
                      })}
                    />
                    <span className="form__error">
                      {errors.billingNip && errorRequired}
                    </span>
                  </div>
                </div>
              </div>

              <div className="form__select">
                <label className="form__label" htmlFor="billingCountry">
                  {countryLabel}
                  <span className="form__label__required">*</span>
                </label>
                <select
                  name="country"
                  id="billingCountry"
                  {...billingCountry}
                  onChange={e => {
                    if (!shipping) {
                      shippingMethodsChange(false, e.target.value)
                    }
                  }}
                  // shippingCountry
                >
                  {countryList.length &&
                    countryList.map((country, index) => (
                      <option
                        key={`${country}-${index}`}
                        value={country.countryCode}
                      >
                        {country.countryName}
                      </option>
                    ))}
                </select>

                <span className="form__error">
                  {errors.billingCountry && errorRequired}
                </span>
              </div>

              <div className="form__input">
                <label className="form__label" htmlFor="billingAddress1">
                  {streetLabel}
                  <span className="form__label__required">*</span>
                </label>
                <input
                  id="billingAddress1"
                  type="text"
                  {...register("billingAddress1", {
                    required: true,
                  })}
                />
                <span className="form__error">
                  {errors.billingAddress1 && errorRequired}
                </span>
              </div>

              <div className="form__row">
                <div className="form__column">
                  <div className="form__input">
                    <label className="form__label" htmlFor="billingCity">
                      {cityLabel}
                      <span className="form__label__required">*</span>
                    </label>
                    <input
                      id="billingCity"
                      type="text"
                      {...register("billingCity", {
                        required: true,
                      })}
                    />
                    <span className="form__error">
                      {errors.billingCity && errorRequired}
                    </span>
                  </div>
                </div>

                <div className="form__column">
                  <div className="form__input">
                    <label className="form__label" htmlFor="billingPostcode">
                      {zipCodeLabel}
                      <span className="form__label__required">*</span>
                    </label>
                    <input
                      id="billingPostcode"
                      type="text"
                      {...register("billingPostcode", {
                        required: true,
                      })}
                    />
                    <span className="form__error">
                      {errors.billingPostcode && errorRequired}
                    </span>
                  </div>
                </div>
              </div>

              <div className="form__row">
                <div className="form__column">
                  <div className="form__input">
                    <label className="form__label" htmlFor="billingPhone">
                      {phoneLabel}
                      <span className="form__label__required">*</span>
                    </label>
                    <input
                      id="billingPhone"
                      type="tel"
                      {...register("billingPhone", {
                        required: true,
                        pattern: {
                          value:
                            /(\+)?(\(?(\+|00)?48\)?)?[ -]?\d{3}[ -]?\d{3}[ -]?\d{3}/i,
                          message: errorPhone,
                        },
                      })}
                    />
                    <span className="form__error">
                      {errors?.billingPhone
                        ? errors?.billingPhone?.message
                          ? errors?.billingPhone?.message
                          : errorRequired
                        : ""}
                    </span>
                  </div>
                </div>

                <div className="form__column">
                  <div className="form__input">
                    <label className="form__label" htmlFor="billingEmail">
                      {emailLabel}
                      <span className="form__label__required">*</span>
                    </label>
                    <input
                      id="billingEmail"
                      type="email"
                      {...register("billingEmail", {
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: errorEmail,
                        },
                        required: true,
                      })}
                    />
                    <span className="form__error">
                      {errors?.billingEmail
                        ? errors?.billingEmail?.message
                          ? errors?.billingEmail?.message
                          : errorRequired
                        : ""}
                    </span>
                  </div>
                </div>
              </div>

              <div className="form__row">
                <div className="form__checkbox">
                  <label htmlFor="toogleShipping">
                    <input
                      id="toogleShipping"
                      type="checkbox"
                      {...register("toogleShipping")}
                      onChange={() => {
                        if (shipping) {
                          shippingMethodsChange()
                        }
                        toogleShipping(!shipping)
                      }}
                    />
                    <span
                      className="form__checkbox__info form__checkbox--normal"
                      dangerouslySetInnerHTML={{
                        __html: differentShippingLabel,
                      }}
                    />
                  </label>
                </div>
              </div>

              <div
                className={`form__wrapper ${
                  shipping ? "" : "form__wrapper--hidden"
                }`}
              >
                <div className="form__row">
                  <div className="form__column">
                    <div className="form__input">
                      <label
                        className="form__label"
                        htmlFor="shippingFirstName"
                      >
                        {nameLabel}
                        <span className="form__label__required">*</span>
                      </label>
                      <input
                        id="shippingFirstName"
                        type="text"
                        {...register("shippingFirstName", {
                          required: shipping,
                        })}
                      />
                      <span className="form__error">
                        {errors.shippingFirstName && errorRequired}
                      </span>
                    </div>
                  </div>

                  <div className="form__column">
                    <div className="form__input">
                      <label className="form__label" htmlFor="shippingLastName">
                        {surnameLabel}
                        <span className="form__label__required">*</span>
                      </label>
                      <input
                        id="shippingLastName"
                        type="text"
                        {...register("shippingLastName", {
                          required: shipping,
                        })}
                      />
                      <span className="form__error">
                        {errors.shippingLastName && errorRequired}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="form__select">
                  <label className="form__label" htmlFor="shippingCountry">
                    {countryLabel}
                    <span className="form__label__required">*</span>
                  </label>
                  <select
                    name="country"
                    id="shippingCountry"
                    {...shippingCountry}
                    onChange={e => {
                      if (shipping) {
                        shippingMethodsChange(false, e.target.value)
                      }
                    }}
                  >
                    {countryList.length &&
                      countryList.map((country, index) => (
                        <option
                          key={`${country}-${index}`}
                          value={country.countryCode}
                        >
                          {country.countryName}
                        </option>
                      ))}
                  </select>

                  <span className="form__error">
                    {errors.shippingCountry && errorRequired}
                  </span>
                </div>

                <div className="form__input">
                  <label className="form__label" htmlFor="shippingaddress1">
                    {streetLabel}
                    <span className="form__label__required">*</span>
                  </label>
                  <input
                    id="shippingaddress1"
                    type="text"
                    {...register("shippingaddress1", {
                      required: shipping,
                    })}
                  />
                  <span className="form__error">
                    {errors.shippingaddress1 && errorRequired}
                  </span>
                </div>

                <div className="form__row">
                  <div className="form__column">
                    <div className="form__input">
                      <label className="form__label" htmlFor="shippingCity">
                        {cityLabel}
                        <span className="form__label__required">*</span>
                      </label>
                      <input
                        id="shippingCity"
                        type="text"
                        {...register("shippingCity", {
                          required: shipping,
                        })}
                      />
                      <span className="form__error">
                        {errors.shippingCity && errorRequired}
                      </span>
                    </div>
                  </div>

                  <div className="form__column">
                    <div className="form__input">
                      <label className="form__label" htmlFor="shippingPostcode">
                        {zipCodeLabel}
                        <span className="form__label__required">*</span>
                      </label>
                      <input
                        id="shippingPostcode"
                        type="text"
                        {...register("shippingPostcode", {
                          required: shipping,
                        })}
                      />
                      <span className="form__error">
                        {errors.shippingPostcode && errorRequired}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="">
                <div className="form__input">
                  <label className="form__label" htmlFor="comment">
                    {commentLabel}
                  </label>
                  <textarea
                    id="comment"
                    type="text"
                    {...register("comment", {})}
                  ></textarea>
                  <span className="form__error">
                    {errors.comment && errorRequired}
                  </span>
                </div>
              </div>
            </div>
            <div className="checkout__column checkout__column--info">
              <h2 className="checkout__heading heading--big heading--dash">
                {orderHeading}
              </h2>
              {shippingMethodsMessage &&
              shippingMethodsMessage !== "virtual" ? (
                <div className="checkout__column__wrapper">
                  <div className="checkout__order">
                    <h3 className="checkout__heading heading--bigNormal">
                      {shippingMethodsMessage}
                    </h3>
                    {loadingShipping && (
                      <div className="checkout__order__loader">
                        <Spin />
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div className="checkout__column__wrapper">
                  <div className="checkout__order">
                    {cart?.products ? (
                      <>
                        <div className="checkout__order__products">
                          <p className="checkout__order__heading">
                            {productLabel}
                          </p>
                          <CheckoutProducts products={cart?.products} />
                        </div>

                        {cart.totalProductsWithoutCoupnFloat ? (
                          <div className="checkout__order__pay">
                            <p className="checkout__order__heading">
                              {priceLabel}
                            </p>
                            <p className="checkout__order__pay__price">
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: cart?.totalProductsWithoutCoupnPrice,
                                }}
                              />
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                        {cart?.subscription ? (
                          <div className="checkout__order__pay">
                            <p className="checkout__order__heading">
                              {priceLabel}
                            </p>
                            <p className="checkout__order__pay__price">
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: cart?.subscriptionData.totalPrice,
                                }}
                              />
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      ""
                    )}
                    {cart?.coupons?.length ? (
                      <div className="checkout__order__copuons">
                        <p className="checkout__order__heading">
                          {couponLabel}
                        </p>
                        <CheckoutCoupons coupons={cart?.coupons} />
                      </div>
                    ) : (
                      ""
                    )}
                    {shippingMethodsMessage !== "virtual" ? (
                      <div className="checkout__order__shipping">
                        <p className="checkout__order__heading">
                          {shipingLabel}
                        </p>

                        {shippingMethodsMessage ? (
                          shippingMethodsMessage
                        ) : (
                          <Shipping
                            shippingMethods={shippingMethods}
                            showActionIntegration={true}
                            inpostButton={inpostButton}
                            inpostBoxPlaceholder={inpostBoxPlaceholder}
                            inpostHendleClick={inpostHendleClick}
                            inpostValue={inpostValue}
                            handleChange={(e, integration) => {
                              handleChangeShipping(e, integration)
                            }}
                          />
                        )}
                      </div>
                    ) : (
                      ""
                    )}

                    {cart.subscription ? (
                      cart.totalProductsWithoutCoupnFloat ? (
                        <>
                          {" "}
                          <div className="checkout__order__total">
                            <p className="checkout__order__heading">
                              {totalLabel}
                            </p>
                            <p className="checkout__order__total__price">
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: cart.totalPriceWithCoupon,
                                }}
                              />
                              <span className="checkout__order__total__vat">
                                {totalVatText}
                              </span>
                            </p>
                          </div>
                          <div className="checkout__order__total">
                            <p className="checkout__order__heading">
                              {totalLabel}
                            </p>
                            <p className="checkout__order__total__price">
                              <span
                                dangerouslySetInnerHTML={{
                                  __html:
                                    cart.subscriptionData
                                      .totalPriceWithShipping,
                                }}
                              />
                              <span className="checkout__order__total__vat">
                                {totalVatText}
                              </span>
                            </p>
                          </div>
                        </>
                      ) : (
                        <div className="checkout__order__total">
                          <p className="checkout__order__heading">
                            {totalLabel}
                          </p>
                          <p className="checkout__order__total__price">
                            <span
                              dangerouslySetInnerHTML={{
                                __html:
                                  cart.subscriptionData.totalPriceWithShipping,
                              }}
                            />
                            <span className="checkout__order__total__vat">
                              {totalVatText}
                            </span>
                          </p>
                        </div>
                      )
                    ) : (
                      <div className="checkout__order__total">
                        <p className="checkout__order__heading">{totalLabel}</p>
                        <p className="checkout__order__total__price">
                          <span
                            dangerouslySetInnerHTML={{
                              __html: cart?.totalPriceWithShippingAndCoupon,
                            }}
                          />
                          <span className="checkout__order__total__vat">
                            {totalVatText}
                          </span>
                        </p>
                      </div>
                    )}
                    {/* <div className="checkout__order__payment">
                      <p className="checkout__order__heading">{paymentLabel}</p> */}
                    <PaymentsMethods
                      paymentID={paymentID}
                      paymentLabel={paymentLabel}
                      paymentsMethods={paymentsMethods}
                      handleChangePayment={handleChangePayment}
                      withPickup={withPickup}
                      isPay={isPay}
                    />
                    {paymentsMethods.length ? <></> : ""}
                    {/* </div> */}
                    <div className="form__checkbox">
                      <label htmlFor="newsletter">
                        <input
                          id="newsletter"
                          type="checkbox"
                          {...register("newsletter")}
                        />
                        <span
                          className="form__checkbox__info form__checkbox--normal"
                          dangerouslySetInnerHTML={{
                            __html: newsletterConsentLabel,
                          }}
                        />
                      </label>
                    </div>
                    <div className="form__checkbox">
                      <label htmlFor="regulations">
                        <input
                          id="regulations"
                          type="checkbox"
                          {...register("regulations", {
                            required: true,
                          })}
                        />
                        <span
                          className="form__checkbox__info form__checkbox--normal"
                          dangerouslySetInnerHTML={{
                            __html: regulationsConsentLabel,
                          }}
                        />
                        <span className="form__error">
                          {errors.regulations && errorRequired}
                        </span>
                      </label>
                    </div>
                    <div className="loginPage__button form__button checkout__order__button">
                      <button type="submit" id="payButton">
                        {buttonText}
                      </button>
                      {sending ? <Spin /> : ""}
                    </div>
                    <button
                      style={{ display: "none" }}
                      id="payu_place_order"
                      ref={payuPlaceOrder}
                    ></button>
                    {cart?.subscription ? (
                      <>
                        <input
                          name="payu_token_type"
                          id="payu_token_type"
                          type="hidden"
                          ref={payuTokenType}
                        />
                        <input
                          name="payu_value"
                          id="payu_value"
                          type="hidden"
                          ref={payuValue}
                        />
                        <input
                          name="payu_masked_card"
                          id="payu_masked_card"
                          type="hidden"
                          ref={payuMaskedCard}
                        />
                        <input
                          name="payu_type"
                          id="payu_type"
                          type="hidden"
                          ref={payuType}
                        />
                      </>
                    ) : (
                      ""
                    )}

                    {errorMessage ? (
                      <div className="form__error">{errorMessage}</div>
                    ) : (
                      ""
                    )}
                    {loadingShipping && (
                      <div className="checkout__order__loader">
                        <Spin />
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </form>
        </section>
      </div>
    </>
  )
}
export default Checkout
