import React, { useContext } from "react";
import { AppContext } from "../context/AppContext";

import "./style.scss"

const ShippingItem = ({
  title,
  description,
  handleChange,
  price,
  integration,
  showActionIntegration,
  id_shipping,
  inpostButton,
  inpostBoxPlaceholder,
  inpostValue,
  inpostHendleClick,
  withPickup
}) => {
  const { shippingID } = useContext(AppContext);
  // console.log(id_shipping);
  // console.log(shippingID);
  return (
    <div className="shippingItem">
      <label htmlFor={id_shipping}>
        {shippingID === id_shipping ? (
          <input type="radio" defaultChecked name="shipping" value={id_shipping} id={id_shipping} />
        ) : (
          <input type="radio" name="shipping" value={id_shipping} id={id_shipping} onChange={((e) => { handleChange(e, withPickup) })} />
        )}
        <span className="shippingItem__label">
          <span className="shippingItem__label__heading">{title}: {price ? <span className="shippingItem__label__price" dangerouslySetInnerHTML={{ __html: price }} /> : ''}</span>
          <span className="shippingItem__label__description">{description}</span>
        </span>
        {showActionIntegration && integration ? (
          <div className={`${shippingID !== id_shipping ? 'hidde' : ''}`}>
            <div className="form__input">
              <label className="form__label" htmlFor="inpost">
                {inpostBoxPlaceholder}
                <span className="form__label__required">*</span>
              </label>
              <input
                disabled={true}
                id="inpost"
                type="text"
                value={inpostValue}
                onClick={(e) => { e.preventDefault(); inpostHendleClick(e) }}
              />
            </div>

            <div className="loginPage__button form__button checkout__order__button">
              <button type="button" onClick={(e) => { e.preventDefault(); inpostHendleClick(e) }} >{inpostButton}</button>
            </div>
          </div>
        ) :
          ''}
      </label>
    </div>
  )
}
export default ShippingItem
